import React from "react"
import { Link } from "gatsby"
import * as styles from "./style.module.css"
import cardImage1 from "../../images/card-rectangle.jpg"
import cardImage2 from "../../images/Rectangle-312.jpg"
import cardImage3 from "../../images/Rectangle-313.jpg"
import cardImage4 from "../../images/Rectangle-314.jpg"
import cardImage5 from "../../images/Rectangle-315.jpg"

const photogallery = () => {
  return (
    <div className={styles.PhotogallerySec}>
      <h3>Erkunden Sie unsere Fotogallerie</h3>
      <Link to="https://www.instagram.com/six7house/" target="_blank">
        Instagram
      </Link>
      <div className={styles.gallerySection}>
        <div className={styles.gallery1}>
          <img className={styles.GridImage} src={cardImage1} alt="mountain" />
        </div>
        <div className={styles.smallerGrid}>
          <div className={styles.grid1}>
            <img className={styles.GridImage} src={cardImage2} alt="card" />
          </div>
          <div className={styles.grid2}>
            <img className={styles.GridImage} src={cardImage4} alt="house" />
          </div>
        </div>
        <div className={styles.biggerGrid}>
          <div className={styles.grid1}>
            <img className={styles.GridImage} src={cardImage3} alt="beach" />
          </div>
          <div className={styles.grid2}>
            <img className={styles.GridImage} src={cardImage5} alt="pool" />
          </div>
        </div>
      </div>
      <div className={styles.mobilebiggerGrid}>
        <div className={styles.grid2}>
          <img className={styles.GridImage} src={cardImage5} alt="decrip" />
        </div>
        <div className={styles.grid1}>
          <img className={styles.GridImage} src={cardImage3} alt="cardimage" />
        </div>
      </div>
    </div>
  )
}

export default photogallery
