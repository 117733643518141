import { Button, Container } from "@mui/material"
import { Box } from "@mui/system"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { PageProps, navigate } from "gatsby"
import * as React from "react"
import { ReactCarousel } from "../components/carousel"
import Layout from "../components/layout"
import { RoomGallery } from "../components/roomGallery"
import Seo from "../components/seo"
import { home } from "../data/home"
import Photogallery from "../components/explore-photogallery/photogallery"
//import ExplorePhotogallery from "../components/explore-photogallery/explore-photogallery.tsx"
import homeVideoBg from "../videos/six7-house-home-bg.mp4"

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const IndexPage: React.FC<PageProps> = props => (
  <Layout data={home.hero}>
    <Seo title="Home" />
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100vh",
        top: "0",
        zIndex: 1,
        "&:before": {
          position: "absolute",
          content: '""',
          top: "0",
          width: "100%",
          height: "100vh",
          background: "rgba(0, 0, 0, 0.22)",
        },
        video: {
          width: "100%",
          height: "100vh",
          objectFit: "cover",
        },
      }}
    >
      <video
        //preload="auto"
        autoPlay
        muted
        loop
        playsInline
        crossOrigin="anonymous"
      >
        <source src={homeVideoBg} type="video/mp4" />
      </video>
    </Box>
    <ReactCarousel imgUrl={home.slider.imgUrl} />
    <Box sx={{ padding: "100px 0" }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: "center",
            maxWidth: "835px",
            margin: "auto",
            padding: "120px 0",
            "@media screen and (max-width: 767px)": {
              padding: "7px 0",
            },
          }}
        >
          <h3>
            Entdecken Sie das six7house. Ein einzigartiger Ort in Hout Bay.
          </h3>
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/about")}
          >
            Entdecke mehr
          </Button>
        </Box>
        <Box
          sx={{
            paddingBottom: "90px",
            paddingTop: "100px",
            iframe: {
              width: "100%",
              height: "553px",
              "@media screen and (max-width: 767px)": {
                height: "250px",
              },
            },
          }}
        >
          <iframe
            width="560"
            height="315"
            //src="https://www.youtube.com/embed/yAoLSRbwxL8"
            src="https://www.youtube.com/embed/XX0qQRVBzkg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <RoomGallery {...props} />
      </Container>
    </Box>
    <Box
      sx={{
        padding: "70px 0 100px",
        minHeight: 500,
        textAlign: "center",
        bgcolor: "#E0E2DF",
      }}
    >
      <Container maxWidth="lg">
        <Photogallery />
        {/* <ExplorePhotogallery /> */}
      </Container>
    </Box>
  </Layout>
)

export default IndexPage
