import homeImg from "../images/home-bg.png"
import slide1 from "../images/carousel-homepage-1.jpg"
import slide2 from "../images/carousel-homepage-2.jpg"
import slide3 from "../images/carousel-homepage-3.jpg"
import slide4 from "../images/carousel-homepage-4.jpg"
import slide5 from "../images/carousel-homepage-5.jpg"
import locationIcon from "../images/icon_fill.png"

//const logo: string = require("../images/svg/icon_fill.svg").default
export const home = {
  hero: {
    heading:
      "Erkunden Sie einen Ort mit der Individualität von Luxusapartments und den Annehmlichkeiten eines Hotels.",
    linkTitle: "Hout Bay, South Africa",
    link: "/",
    // "https://goo.gl/maps/J2oNoYtcJZ4XULkU9",
    linkIcon: locationIcon,
    imgUrl: homeImg,
    isHome: true,
    isContact: true,
    showBookingOptons: true,
    ShowAvailability: true,
  },
  slider: {
    imgUrl: [slide1, slide2, slide3, slide4, slide5],
  },
}
